$(document).ready(function() {

    var winScrollTop=0;

    $.fn.is_on_screen = function(){
        var win = $(window);
        var viewport = {
            top : win.scrollTop(),
            left : win.scrollLeft()
        };
        //viewport.right = viewport.left + win.width();
        viewport.bottom = viewport.top + win.height();

        var bounds = this.offset();
        //bounds.right = bounds.left + this.outerWidth();
        bounds.bottom = bounds.top + this.outerHeight();

        return (!(viewport.bottom < bounds.top || viewport.top > bounds.bottom));
    };

    function parallax() {
      var scrolled = $(window).scrollTop();
      $('.rowrellax').each(function(){

      	 if ($(this).is_on_screen()) {
      	 			var firstTop = $(this).offset().top;
              var $span = $(this).find(".rellax");
              var moveTop = (firstTop-winScrollTop)*0.1 //speed;
              $span.css("transform","translateY("+-moveTop+"px)");
         }

      });
    }

    $(window).scroll(function(e){
      winScrollTop = $(this).scrollTop();
      parallax();
    });

});
